import 'normalize.css'
import 'styles/index.scss'

import {gsap} from 'gsap';
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
gsap.registerPlugin(MorphSVGPlugin);


let totalFrames = 0
const player = document.getElementById('lottie');

function lerp(start, end, t) {
  return (1 - t) * start + t * end
}

player.addEventListener('ready', () => {
  totalFrames = player.getLottie().totalFrames
  
  window.addEventListener('mousemove', (e) => {
    const xPosPercentage = e.clientX / window.innerWidth
    const targetFrame = Math.ceil(lerp(0, totalFrames, xPosPercentage))
    
    player.seek(targetFrame)
  })
});